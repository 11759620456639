import React, { useState } from "react";
import {
  TextField,
  Button,
  Alert,
  Snackbar,
  Box,
  Typography,
  IconButton,
  Tooltip,
  FormHelperText,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { parsePhoneNumber } from "awesome-phonenumber";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { capitalize } from "lodash";
import { Stack } from "@mui/system";
import ky from "ky";

import { calculateAge } from "../../utils/dateUtils";

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  birthdate: "",
  gender: "select",
  phone: "",
  referrer: "",
  unitType: "select",
  unitNumber: "",
  emergencyContactName: "",
  emergencyContactRelation: "",
  emergencyContactPhone: "",
  shirtOrders: [
    {
      size: "select",
      quantity: 1,
    },
  ],
  isPlaying: "select",
};

// These keys will not be checked for empty values during form validation
const excludedKeys = [
  "emergencyContactName",
  "emergencyContactRelation",
  "emergencyContactPhone",
  "referrer",
  "unitType",
  "unitNumber",
];

const Form = ({ datastore }) => {
  let navigate = useNavigate();

  const [formValues, setFormValues] = useState(defaultValues);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertLevel, setAlertLevel] = useState("error");
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emergencyPhoneError, setEmergencyPhoneError] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // If email field is being updated, validate the email
    if (name === "email") {
      setEmailError(!isValidEmail(value));
      setFormValues({
        ...formValues,
        [name]: value,
      });
    } else if (name === "firstName" || name === "lastName") {
      setFormValues({
        ...formValues,
        [name]: capitalize(value),
      });
    } else if (name === "phone") {
      const pn = parsePhoneNumber(value, { regionCode: "US" });
      if (pn.valid) {
        setPhoneError(false);
      } else {
        setPhoneError(true);
      }
      setFormValues({
        ...formValues,
        [name]: pn.number.national,
      });
    } else if (name === "emergencyContactPhone") {
      const pn = parsePhoneNumber(value, { regionCode: "US" });
      if (pn.valid) {
        setEmergencyPhoneError(false);
      } else {
        setEmergencyPhoneError(true);
      }
      setFormValues({
        ...formValues,
        [name]: pn.number.national,
      });
    } else if (name === "birthdate") {
      const dateOfBirth = new Date(value);
      const today = new Date();

      // Check if the date of birth is in the future or if the participant will be at least 14 on the event date
      if (
        dateOfBirth > today ||
        calculateAge(value, datastore.config.eventDate) < 13
      ) {
        setAlertLevel("error");
        setAlertMessage(
          "Please make sure the birthdate is valid and the person will be at least 13.5 years old on the date of the event.",
        );
        setAlertIsOpen(true);
      } else {
        setFormValues({
          ...formValues,
          [name]: value,
        });
      }
    } else if (name === "isPlaying") {
      setFormValues({
        ...formValues,
        [name]: value === "true",
      });
    } else if (name.startsWith("shirtOrder-")) {
      // eslint-disable-next-line no-unused-vars
      const [_, index, field] = name.split("-");
      const updatedShirtOrders = [...formValues.shirtOrders];
      updatedShirtOrders[parseInt(index, 10)][field] = value;
      setFormValues((prev) => ({
        ...prev,
        shirtOrders: updatedShirtOrders,
      }));
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const addShirtOrder = () => {
    setFormValues((prev) => ({
      ...prev,
      shirtOrders: [...prev.shirtOrders, { size: "select", quantity: 1 }],
    }));
  };

  const removeShirtOrder = (index) => {
    setFormValues((prev) => ({
      ...prev,
      shirtOrders: prev.shirtOrders.filter((_, i) => i !== index),
    }));
  };

  const isValidEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  /**
   * Determines if shirt orders are still open.
   *
   * @returns {boolean} - True if shirt orders are open, false otherwise.
   */
  const isShirtOrderOpen = () => {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    const shirtCloseDate = new Date(
      datastore.config.shirtRegCloseDate,
    ).setHours(0, 0, 0, 0);
    return shirtCloseDate >= currentDate;
  };

  return (
    <Box
      component="form"
      sx={{
        mt: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <TextField
        margin="normal"
        required
        fullWidth
        id="firstName"
        label="First Name"
        name="firstName"
        helperText={"Player's first name"}
        value={formValues.firstName}
        onChange={handleInputChange}
        sx={{ maxWidth: 250 }}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="lastName"
        label="Last Name"
        name="lastName"
        helperText={"Player's last name"}
        value={formValues.lastName}
        onChange={handleInputChange}
        sx={{ maxWidth: 250 }}
      />
      {/* TODO: Validate email by sending email?*/}
      <TextField
        error={emailError}
        helperText={
          emailError
            ? "Invalid email"
            : "This is where your receipt will be sent."
        }
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        value={formValues.email}
        onChange={handleInputChange}
        sx={{ maxWidth: 250 }}
      />
      <TextField
        margin="normal"
        fullWidth
        id="birthdate-input"
        name="birthdate"
        label="Birthdate"
        helperText="Must be at least 13 years old."
        type="date"
        value={formValues.birthdate}
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true, required: true }}
        sx={{ maxWidth: 250 }}
      />
      <TextField
        select
        margin="normal"
        fullWidth
        SelectProps={{
          native: true,
        }}
        required
        value={formValues.gender}
        label="Gender"
        name="gender"
        onChange={handleInputChange}
        sx={{ maxWidth: 250 }}
      >
        <option value="select" disabled={true}>
          Select
        </option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
        <option value="Other">Other</option>
      </TextField>
      <Box width={250}>
        <FormHelperText
          sx={{
            maxWidth: 250,
            ml: "14px",
            mt: -0.75,
            textAlign: "left",
            mb: 1.5,
          }}
        >
          Player's Gender
        </FormHelperText>
      </Box>
      <TextField
        margin="normal"
        fullWidth
        id="phone"
        label="Phone Number"
        helperText="10 digit phone number"
        name="phone"
        required
        value={formValues.phone}
        onChange={handleInputChange}
        sx={{ maxWidth: 250 }}
      />
      <TextField
        margin="normal"
        fullWidth
        id="referrer"
        label="Referrer Name"
        name="referrer"
        helperText="Let us know who refered you to attent our event :)"
        value={formValues.referrer}
        onChange={handleInputChange}
        sx={{ maxWidth: 250 }}
      />
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          mb: 0,
          mt: 1,
          maxWidth: 250,
        }}
      >
        <TextField
          select
          margin="normal"
          fullWidth
          SelectProps={{
            native: true,
          }}
          value={formValues.unitType}
          label="Unit Type"
          name="unitType"
          onChange={handleInputChange}
        >
          <option value="select" disabled={true}>
            Select
          </option>
          <option value="Troop">Troop</option>
          <option value="Crew">Crew</option>
          <option value="Ship">Ship</option>
        </TextField>
        <TextField
          margin="normal"
          fullWidth
          id="unit-number-input"
          name="unitNumber"
          label="Unit Number"
          type="number"
          value={formValues.unitNumber}
          onChange={handleInputChange}
          onWheel={(e) => e.target.blur()}
          sx={{ maxWidth: 250 }}
        />
      </Box>
      <Box width={250}>
        <FormHelperText
          sx={{
            maxWidth: 250,
            ml: "14px",
            mt: -0.75,
            textAlign: "left",
            mb: 1.5,
          }}
        >
          If you are associated with a unit, please enter it
        </FormHelperText>
      </Box>
      <TextField
        select
        margin="normal"
        fullWidth
        SelectProps={{
          native: true,
        }}
        required
        value={formValues.isPlaying}
        name="isPlaying"
        onChange={handleInputChange}
        sx={{ maxWidth: 250 }}
      >
        <option value="select" disabled={true}>
          Select
        </option>
        {!isShirtOrderOpen() ? (
          <option value="true">Yes, I want to play broomball!</option>
        ) : (
          <>
            <option value="true">Yes, I want to play broomball!</option>
            <option value="false">No, I just want a t-shirt :(</option>
          </>
        )}
      </TextField>
      <Box width={250}>
        <FormHelperText
          sx={{
            maxWidth: 250,
            ml: "14px",
            mt: -0.75,
            textAlign: "left",
            mb: 1.5,
          }}
        >
          Will you be playing broomball?
        </FormHelperText>
      </Box>
      {formValues.isPlaying !== true ? null : (
        <>
          <TextField
            margin="normal"
            required
            fullWidth
            id="emergency-contact-name-input"
            name="emergencyContactName"
            label="Emergency Contact Name"
            helperText="Full name of emergency contact"
            type="text"
            value={formValues.emergencyContactName}
            onChange={handleInputChange}
            sx={{ maxWidth: 250 }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="emergency-contact-phone-input"
            name="emergencyContactPhone"
            label="Emergency Contact Phone"
            helperText="10 digit phone number"
            type="text"
            value={formValues.emergencyContactPhone}
            onChange={handleInputChange}
            sx={{ maxWidth: 250 }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            helperText="e.g. Parent, Guardian, etc."
            id="emergency-contact-relation-input"
            name="emergencyContactRelation"
            label="Emergency Contact Relation"
            type="text"
            value={formValues.emergencyContactRelation}
            onChange={handleInputChange}
            sx={{ maxWidth: 250 }}
          />
        </>
      )}
      {isShirtOrderOpen() && (
        <>
          {formValues.shirtOrders.map((shirtOrder, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                mb: 1,
                mt: 2,
                maxWidth: 260,
              }}
            >
              <TextField
                select
                fullWidth
                SelectProps={{
                  native: true,
                }}
                required
                value={shirtOrder.size}
                label={`Size #${index + 1}`}
                name={`shirtOrder-${index}-size`}
                onChange={handleInputChange}
              >
                <option value="select" disabled>
                  Select
                </option>
                <option value="None">None</option>
                <option value="XS">XS</option>
                <option value="Small">Small</option>
                <option value="Medium">Medium</option>
                <option value="Large">Large</option>
                <option value="XL">XL</option>
                <option value="XXL">XXL</option>
                <option value="XXXL">XXXL</option>
              </TextField>
              <TextField
                required
                fullWidth
                type="number"
                value={shirtOrder.quantity}
                label="Quantity"
                name={`shirtOrder-${index}-quantity`}
                onChange={handleInputChange}
                onWheel={(e) => e.target.blur()}
                sx={{ maxWidth: formValues.shirtOrders.length > 1 ? 78 : null }}
              />
              {formValues.shirtOrders.length > 1 && (
                <Tooltip title={`Remove Shirt #${index + 1}`}>
                  <IconButton
                    variant="contained"
                    color="error"
                    onClick={() => removeShirtOrder(index)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          ))}
          <Box width={250}>
            <FormHelperText
              sx={{
                maxWidth: 250,
                ml: "14px",
                mt: -0.75,
                textAlign: "left",
                mb: 1.5,
              }}
            >
              If you would like to order a shirt, please select the size and
              quantity. Otherwise, select "None".
            </FormHelperText>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={addShirtOrder}
            sx={{ mt: 2 }}
          >
            <AddIcon />
            <Typography variant="subtitle2" mt={0.3}>
              Additional Shirt
            </Typography>
          </Button>
        </>
      )}
      <Button
        onClick={() => {
          if (formValues.isPlaying === "select") {
            setAlertLevel("error");
            setAlertMessage(
              "Please select if you are playing broomball or not.",
            );
            setAlertIsOpen(true);
            return;
          }
          ky.post(window._env_.REACT_APP_API_URL + "/check", {
            json: { configId: datastore.config.configId, ...formValues },
            timeout: window._env_.REACT_APP_TIMEOUT,
          })
            .json()
            .then((userExists) => {
              if (emailError) {
                setAlertLevel("error");
                setAlertMessage("Please make sure the email address is valid!");
                setAlertIsOpen(true);
              } else if (phoneError) {
                setAlertLevel("error");
                setAlertMessage("Please make sure your phone number is valid!");
                setAlertIsOpen(true);
              } else if (
                formValues.isPlaying === false &&
                !isShirtOrderOpen()
              ) {
                setAlertLevel("error");
                setAlertMessage(
                  "You cannot register after the t-shirt order deadline.",
                );
                setAlertIsOpen(true);
              } else if (
                formValues.isPlaying === true &&
                (formValues.emergencyContactName === "" ||
                  formValues.emergencyContactRelation === "" ||
                  formValues.emergencyContactPhone === "" ||
                  emergencyPhoneError)
              ) {
                setAlertLevel("error");
                setAlertMessage(
                  "Please provide valid emergency contact details for players!",
                );
                setAlertIsOpen(true);
              } else if (userExists) {
                setAlertLevel("error");
                setAlertMessage(
                  "A user with the email and name you provided already exists!",
                );
                setAlertIsOpen(true);
              } else if (
                Object.entries(formValues).some(
                  ([key, value]) =>
                    !excludedKeys.includes(key) &&
                    (value === "" || value === "select"),
                )
              ) {
                setAlertLevel("error");
                setAlertMessage(
                  "Please make sure all required fields are populated!",
                );
                setAlertIsOpen(true);
              } else {
                // Set size to None if it is "select"
                if (formValues.shirtOrders[0].size === "select") {
                  formValues.shirtOrders[0].size = "None";
                }
                // Set 0 shirts if size is None
                if (formValues.shirtOrders[0].size === "None") {
                  formValues.shirtOrders[0].quantity = 0;
                }
                // Set unitType to empty string if it is "select"
                if (formValues.unitType === "select") {
                  formValues.unitType = "";
                }
                // Proceed to the next step if all validations pass
                window.sessionStorage.setItem(
                  "formValues",
                  JSON.stringify(formValues),
                );
                navigate("/register/review");
              }
            })
            .catch(() => {
              setAlertLevel("error");
              setAlertMessage(
                "Error: Could not check if username already exists.",
              );
              setAlertIsOpen(true);
            });
        }}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Continue to Payment
      </Button>
      <Stack justifyContent="center" alignItems="center" spacing={0.5}>
        <Typography variant="body1">
          <strong>Need Help?</strong>
        </Typography>
        <Link to="/faq" variant="body2">
          Frequently Asked Questions
        </Link>
        <Link to="/contact" variant="body2">
          Contact Us
        </Link>
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertIsOpen}
        autoHideDuration={6000}
        onClose={() => setAlertIsOpen(false)}
      >
        <Alert onClose={() => setAlertIsOpen(false)} severity={alertLevel}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
export default Form;
