import React from "react";
import MUIDataTable from "mui-datatables";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { utils, writeFileXLSX } from "xlsx";

import { calculateAge } from "../../utils/dateUtils";

const ParticipantTable = ({ datastore, participantJson }) => {
  const [filtered, setFiltered] = React.useState(false);

  const columns = [
    {
      name: "participantId",
      label: "ID",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "name",
      label: "Full Name",
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: "firstName",
      label: "First Name",
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "birthdate",
      label: "Birthdate",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "birthdate",
      label: "Age at Event",
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value) => {
          return calculateAge(value, datastore.config.eventDate);
        },
      },
    },
    {
      name: "gender",
      label: "Gender",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return capitalizeFirstLetter(value);
        },
      },
    },
    {
      name: "unit",
      label: "Unit",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "unitType",
      label: "Unit Type",
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: "unitNumber",
      label: "Unit Number",
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography noWrap={true} variant="body2">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "emailAddress",
      label: "Participant Email",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return <Link href={"mailto:" + value}>Email</Link>;
        },
      },
    },
    {
      name: "referrer",
      label: "Referrer",
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "emergencyContactName",
      label: "Emergency Contact Name",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "emergencyContactPhoneNumber",
      label: "Emergency Contact Phone Number",
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => {
          return (
            <Typography noWrap={true} variant="body2">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "emergencyContactRelation",
      label: "Emergency Contact Relation",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "shirtOrders",
      label: "Shirt Orders",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          if (value && value.length > 0 && value[0].size === "None") {
            return (
              <Typography variant="body2" align="center">
                <strong>No Shirt!</strong>
              </Typography>
            );
          }
          return (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2">
                      <strong>Size</strong>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      <strong>Quantity</strong>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {value.map((shirt) => (
                  <TableRow key={shirt.shirtOrderId}>
                    <TableCell>
                      <Typography variant="body2">{shirt.size}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{shirt.quantity}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          );
        },
        filterOptions: {
          names: [
            "XS",
            "Small",
            "Medium",
            "Large",
            "XL",
            "XXL",
            "XXXL",
            "None",
          ], // Specify filter options
          logic: (shirtSizes, filters) => {
            if (filters.length === 0) return false; // Include all rows if no filter is applied
            const sizes = shirtSizes.map((shirt) => shirt.size); // Extract sizes from the column value
            return !filters.some((filter) => sizes.includes(filter)); // Exclude rows not matching filter
          },
          display: (filterList, onChange, index, column) => {
            // Customize filter display with checkboxes
            const sizes = [
              "XS",
              "Small",
              "Medium",
              "Large",
              "XL",
              "XXL",
              "XXXL",
              "None",
            ];
            return (
              <FormControl component="fieldset">
                <FormGroup>
                  {sizes.map((size) => (
                    <FormControlLabel
                      key={size}
                      control={
                        <Checkbox
                          checked={filterList[index].includes(size)}
                          onChange={(event) => {
                            const newFilterList = [...filterList[index]];
                            if (event.target.checked) {
                              newFilterList.push(size);
                            } else {
                              const sizeIndex = newFilterList.indexOf(size);
                              newFilterList.splice(sizeIndex, 1);
                            }
                            onChange(newFilterList, index, column);
                          }}
                        />
                      }
                      label={size}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: "paymentMethod",
      label: "Payment Method",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "paymentAmount",
      label: "Gross Payment",
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => {
          return formatAsDollar(value);
        },
      },
    },
    {
      name: "netPaymentAmount",
      label: "Net Payment",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return formatAsDollar(value);
        },
      },
    },
    {
      name: "payPalFee",
      label: "Fees",
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => {
          return formatAsDollar(value);
        },
      },
    },
    {
      name: "captureId",
      label: "Paypal ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "registrationTime",
      label: "Registration Date",
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => {
          return new Date(value).toLocaleString("en-US", {
            timeZone: "America/Los_Angeles",
          });
        },
      },
    },
    {
      name: "isRefunded",
      label: "Refunded?",
      options: {
        display: false,
        sort: true,
        customBodyRender: (value) => {
          return value ? "Yes" : "No";
        },
      },
    },
    {
      name: "isPlaying",
      label: "Is Playing?",
      options: {
        display: true,
        sort: true,
        filter: true,
        customBodyRender: (value) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {value ? (
                <CheckCircleOutlineIcon color="success" />
              ) : (
                <CancelOutlinedIcon color="error" />
              )}
            </div>
          );
        },
      },
    },

    {
      name: "shirtOrders",
      label: "Received Shirt?",
      options: {
        display: true,
        sort: false,
        filter: false,
        customBodyRender: (val) => {
          if (val && val[0]?.size === "None") {
            return (
              <Typography variant="body2" sx={{ pl: 1 }}>
                N/A
              </Typography>
            );
          } else {
            return <Checkbox />;
          }
        },
      },
    },
  ];

  const capitalizeFirstLetter = ([first = "", ...rest]) => {
    return [first.toUpperCase(), ...rest].join("");
  };

  function formatAsDollar(input) {
    const number = parseFloat(input);
    if (isNaN(number)) {
      return "Invalid input"; // or handle as needed
    }

    return number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    download: true,
    downloadOptions: {
      filterOptions: {
        useDisplayedRowsOnly: true,
        useDisplayedColumnsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      // Reshape data
      let formattedData = [];
      const keys = columns.map((c) => c.label);

      data.forEach((entry) => {
        const obj = {};
        keys.forEach((element, index) => {
          const cellValue = entry.data[index];
          // Handle objects and primitive values
          obj[element] =
            typeof cellValue === "object" && cellValue !== null
              ? JSON.stringify(cellValue) // Convert object to JSON string
              : cellValue; // Use primitive values as-is
        });
        formattedData.push(obj);
      });

      // Convert to JSON to XLSX object
      const ws = utils.json_to_sheet(formattedData);

      // Determine widths for each column
      const fitToColumn = (data) => {
        const columnWidths = [];
        for (const property in data[0]) {
          columnWidths.push({
            wch: Math.max(
              property ? property.toString().length : 0,
              ...data.map((obj) =>
                obj[property] ? obj[property].toString().length : 0,
              ),
            ),
          });
        }
        return columnWidths;
      };

      // Set column widths
      ws["!cols"] = fitToColumn(formattedData);

      // Convert worksheet into workbook
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");

      // Save workbook to file
      writeFileXLSX(
        wb,
        !filtered
          ? datastore.config.eventName + ".xlsx"
          : datastore.config.eventName + " Filtered.xlsx",
        { cellStyles: true },
      );

      // Return false to cancel original CSV download
      return false;
    },

    print: true,
    viewColumns: true,
    responsive: "standard",
    sortThirdClickReset: true,
    onFilterChange: (changedColumn, filterList) => {
      setFiltered(filterList.flat().length !== 0);
    },
  };

  return (
    <MUIDataTable
      data={participantJson.sort((a, b) => a.participantId - b.participantId)}
      columns={columns}
      options={options}
    />
  );
};
export default ParticipantTable;
