import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Box,
  Backdrop,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ky from "ky";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import PayPal from "../paypal/PayPal";

const ReviewOrder = ({ datastore }) => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [formValues, setFormValues] = useState(
    JSON.parse(window.sessionStorage.getItem("formValues")),
  );
  const [promoCode, setPromoCode] = useState("");
  const [addFees, setAddFees] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [backdropMessage, setBackdropMessage] = useState(
    "Processing your registration...",
  );
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [totalPrice, setTotalPrice] = useState(0.0);
  const [payLater, setPayLater] = useState(false);

  const formatPromoCode = (code) => {
    if (code === "cash") {
      return "Cash";
    } else if (
      code === "scout-account" ||
      code === "scoutaccount" ||
      code === "scout account"
    ) {
      return "Scout Account";
    } else if (code === "dev") {
      return "Developer";
    }
  };

  useEffect(() => {
    let basePrice = 0;

    // Calculate base price based on form values
    if (formValues.isPlaying === false) {
      basePrice =
        datastore.config.shirtPrice *
        formValues.shirtOrders.reduce(
          (sum, order) => sum + parseInt(order.quantity),
          0,
        );
    } else {
      basePrice =
        formValues.shirtSize === "None"
          ? datastore.config.eventPrice
          : datastore.config.eventPrice +
            datastore.config.shirtPrice *
              formValues.shirtOrders.reduce(
                (sum, order) => sum + parseInt(order.quantity),
                0,
              );
    }

    if (
      promoCode.toLowerCase() === "dev" ||
      promoCode.toLowerCase() === "cash" ||
      promoCode.toLowerCase() === "scout-account" ||
      promoCode.toLowerCase() === "scout account" ||
      promoCode.toLowerCase() === "scoutaccount"
    ) {
      setPayLater(true);
    } else {
      setPayLater(false);
    }

    // Add or remove fees
    let updatedPrice = basePrice;
    if (addFees) {
      updatedPrice += 2.0;
    } else if (totalPrice > basePrice) {
      updatedPrice -= 2.0;
    }
    setTotalPrice(updatedPrice);
  }, [promoCode, formValues, datastore.config, addFees]);

  function delayAndGo(path, time) {
    setTimeout(() => navigate(path), time);
  }

  const handleClick = async (orderId, captureId, method) => {
    setIsLoading(true);
    ky.post(window._env_.REACT_APP_API_URL + "/register", {
      json: {
        participantInfo: {
          configId: datastore.config.configId,
          ...formValues,
        },
        paymentInfo: {
          method,
          amount: totalPrice.toString(),
          orderId,
          captureId,
        },
      },
      timeout: window._env_.REACT_APP_TIMEOUT,
    })
      .json()
      .then((resp) => {
        console.log(resp);
        setBackdropMessage("Registration successful! Redirecting...");
        // Check response for success criteria if needed
        setMessage(
          "Successfully registered. Please check your email for registration confirmation!",
        );
        setSeverity("success");
        setOpen(true);
        delayAndGo("/", 6000);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setMessage("Registration Failed! Do NOT pay again! Please contact us!");
        setSeverity("error");
        setOpen(true);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      sx={{ textAlign: "center" }}
    >
      <Backdrop
        open={isLoading}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: "#fff" }}
      >
        <Box textAlign="center">
          <CircularProgress color="inherit" />
          <Typography variant="h6" mt={2}>
            {backdropMessage}
          </Typography>
        </Box>
      </Backdrop>

      <Header />
      <Box flexGrow={1}>
        <Typography variant="h4" pt={2}>
          <strong>Review Info:</strong>
        </Typography>
        <Box display="flex" justifyContent="center" pt={2}>
          <Table size="small" sx={{ maxWidth: "300px" }}>
            <TableBody>
              {[
                {
                  label: "Name",
                  value: `${formValues.firstName} ${formValues.lastName}`,
                },
                { label: "Birthdate", value: formValues.birthdate },
                { label: "Gender", value: formValues.gender },
                { label: "Email", value: formValues.email },
                { label: "Phone Number", value: formValues.phone },
                {
                  label: "Unit",
                  value: `${formValues.unitType} ${formValues.unitNumber}`,
                },
                {
                  label: "Playing Broomball",
                  value: `${formValues.isPlaying ? "Yes" : "No"}`,
                },
                ...(formValues.isPlaying
                  ? [
                      {
                        label: "EC Name",
                        value: formValues.emergencyContactName,
                      },
                      {
                        label: "EC Relation",
                        value: formValues.emergencyContactRelation,
                      },
                      {
                        label: "EC Number",
                        value: formValues.emergencyContactPhone,
                      },
                    ]
                  : []),
              ].map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="body1">
                      <strong>{item.label}:</strong>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{item.value}</Typography>
                  </TableCell>
                </TableRow>
              ))}

              {/* Render shirt orders */}
              {formValues.shirtOrders.length > 0 && (
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography variant="h6">
                      <strong>Shirt Orders:</strong>
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {formValues.shirtOrders.map((shirtOrder, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="body1">
                      <strong>#{index + 1}:</strong>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <strong>Size:</strong> {shirtOrder.size}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Quantity:</strong> {shirtOrder.quantity}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>

        {!payLater && (
          <Box
            my={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body1" maxWidth={200}>
              Would you like to add <strong>$2.00</strong> to help cover
              processing fees?
            </Typography>
            <Checkbox onChange={(e) => setAddFees(e.target.checked)} />
          </Box>
        )}

        <Typography variant="body1" mt={2}>
          <strong>Total:</strong> ${totalPrice}
        </Typography>

        <Box my={2}>
          <TextField
            size="small"
            label="Promo Code"
            variant="outlined"
            onChange={(e) => setPromoCode(e.target.value)}
          />
        </Box>
        {!payLater ? (
          <Box display="flex" justifyContent="center">
            <PayPal
              key={totalPrice}
              liveClientId={datastore.config.paypalClientId}
              handleClick={handleClick}
              price={totalPrice}
            />
          </Box>
        ) : (
          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              onClick={() => {
                handleClick(
                  "None",
                  "None",
                  formatPromoCode(promoCode.toLowerCase()),
                );
              }}
            >
              Register & Pay Later
            </Button>
          </Box>
        )}
      </Box>
      <Footer />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ReviewOrder;
