import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider } from "@mui/material";
import MUIDataTable from "mui-datatables";

function summarizeShirtOrders(orders, shirtPrice) {
  // Predefined order of sizes
  const sizeOrder = [
    "XXS",
    "XS",
    "Small",
    "Medium",
    "Large",
    "XL",
    "XXL",
    "XXXL",
    "XXXXL",
    "Total",
  ];

  // Initialize an empty object to store the summary
  const sizeSummary = {};

  // Iterate over each order
  orders.forEach((order) => {
    const { size, quantity } = order;

    // Filter out invalid sizes and quantities
    if (size !== "None" && size !== "select" && quantity > 0) {
      // Add to the summary, incrementing the count for each size
      if (!sizeSummary[size]) {
        sizeSummary[size] = 0; // Initialize if size is not yet in summary
      }
      sizeSummary[size] += quantity;
    }
  });

  // Convert the summary object to a table format
  const resultTable = Object.keys(sizeSummary).map((size) => ({
    size,
    quantity: sizeSummary[size],
    amountCollected: sizeSummary[size] * shirtPrice,
  }));

  // Add a summary row for the total shirts
  const totalShirts = Object.values(sizeSummary).reduce(
    (sum, qty) => sum + qty,
    0,
  );

  const totalAmount = Object.values(sizeSummary).reduce(
    (sum, qty) => sum + qty * shirtPrice,
    0,
  );

  resultTable.push({
    size: "Total",
    quantity: totalShirts,
    amountCollected: totalAmount,
  });

  // Custom sort function based on the predefined order
  resultTable.sort((a, b) => {
    return sizeOrder.indexOf(a.size) - sizeOrder.indexOf(b.size);
  });

  return resultTable;
}

const boldRowStyle = (rowData) => {
  return rowData.size === "Total" ? { fontWeight: "bold" } : {};
};

const ShirtOrderTable = ({ data, datastore }) => {
  const [shirtOrders, setShirtOrders] = useState([]);

  useEffect(() => {
    const orders = data.map((participant) => {
      return participant.shirtOrders;
    });
    const summarizedOrders = summarizeShirtOrders(
      orders.flat(),
      datastore.config.shirtPrice,
    );
    setShirtOrders(summarizedOrders);
  }, [data]);

  return (
    <div style={{ marginBottom: "16px" }}>
      <Accordion defaultExpanded={false} sx={{ borderRadius: "4px" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">
            <strong>Shirt Order Summary</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider sx={{ marginBottom: 2.25 }} />
          <MUIDataTable
            title={<strong>Shirt Orders</strong>}
            data={shirtOrders}
            columns={[
              {
                name: "size",
                label: "Size",
                options: {
                  customHeadLabelRender: () => {
                    return (
                      <Typography variant="body1">
                        <strong>Size</strong>
                      </Typography>
                    );
                  },
                  customBodyRender: (value, tableMeta) => {
                    const rowData = shirtOrders[tableMeta.rowIndex];
                    return (
                      <Typography style={boldRowStyle(rowData)}>
                        {value}
                      </Typography>
                    );
                  },
                },
              },
              {
                name: "quantity",
                label: "Quantity",
                options: {
                  customHeadLabelRender: () => {
                    return (
                      <Typography variant="body1">
                        <strong>Quantity</strong>
                      </Typography>
                    );
                  },
                  customBodyRender: (value, tableMeta) => {
                    const rowData = shirtOrders[tableMeta.rowIndex];
                    return (
                      <Typography style={boldRowStyle(rowData)}>
                        {value}
                      </Typography>
                    );
                  },
                },
              },
              {
                name: "amountCollected",
                label: "Amount Collected",
                options: {
                  customHeadLabelRender: () => {
                    return (
                      <Typography variant="body1">
                        <strong>Amount Collected</strong>
                      </Typography>
                    );
                  },
                  customBodyRender: (value, tableMeta) => {
                    const rowData = shirtOrders[tableMeta.rowIndex];
                    return (
                      <Typography style={boldRowStyle(rowData)}>
                        ${value.toFixed(2)}
                      </Typography>
                    );
                  },
                },
              },
            ]}
            options={{
              selectableRows: "none",
              print: true,
              download: true,
              filter: false,
              viewColumns: false,
              pagination: false,
              search: false,
              responsive: "standard",
            }}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ShirtOrderTable;
