import React from "react";
import { Typography, Box, Container, Divider } from "@mui/material";

import Form from "../Form/Form";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const InfoRegister = ({ datastore }) => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <Box flexGrow={1}>
        <Container sx={{ pt: 4, textAlign: "center" }}>
          <Typography variant="h4" sx={{ mb: 3, fontWeight: "bold" }}>
            Broomball Registration
          </Typography>

          <Divider sx={{ mb: 3 }} />

          <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold" }}>
            Registration Options
          </Typography>

          <Box sx={{ textAlign: "left", mb: 3, mx: "auto", maxWidth: 600 }}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Choose one of the following options to participate in our
              Broomball event:
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>1.</strong> <strong>Pay to Play:</strong> The cost to
              participate in the Broomball event is{" "}
              <strong>${datastore.config.eventPrice}</strong> per person. This
              does <strong>NOT</strong> include a t-shirt.
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>2.</strong> <strong>Play & Buy a T-Shirt:</strong> Add
              t-shirts to your registration for an additional{" "}
              <strong>${datastore.config.shirtPrice} each</strong>.
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>3.</strong> <strong>Buy a T-Shirt Only:</strong> If you do
              not wish to play Broomball, you can still support us by buying a
              t-shirt for <strong>${datastore.config.shirtPrice}</strong>.
            </Typography>
          </Box>

          <Divider sx={{ mb: 3 }} />

          <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold" }}>
            Important Information
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            T-shirts must be purchased with your registration before the cutoff
            date: <strong>{datastore.config.shirtRegCloseDate}</strong>.
          </Typography>
        </Container>

        <Divider sx={{ mb: 3 }} />

        <Container sx={{ pb: 2 }}>
          <Form datastore={datastore} />
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default InfoRegister;
